// import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'
import React from 'react'

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="inner">
        <div className="common-header">
          <h1>プライバシーポリシー</h1>
          <p className="subtitle">Privacy Policy</p>
        </div>
        {/* /.common-header */}

        <p>
          株式会社AlgoNaut（以下「当社」という。）は、お客様その他当社の関係者（以下「お客様等」という。）の個人情報等（第2条に定義する意味を有します。以下同じ。）の適切な取扱い及び保護が当社にとって重要な責務であると考えております。
          <br />
          当社は、個人情報等の保護のため、個人情報等の取扱いの基本方針を以下のとおりプライバシーポリシー（以下「本ポリシー」という。）として定め、お客様等の個人情報等の適切な取扱い及び保護を行って参ります。
        </p>

        <h2>第1条（本ポリシーの適用範囲）</h2>
        <p>
          1.
          本ポリシーは、当社が行う全ての事業、当社が提供する全ての製品及びサービス等に関する個人情報等について適用されます。なお、当社が特定の事業、製品、サービス等に関する個人情報等について特別のポリシーを定めた場合、同ポリシーが優先して適用されます。
        </p>

        <p>
          2.
          お客様等は、予め本ポリシーを理解し、本ポリシーに同意した上で、当社の提供する製品及びサービスを利用するものとします。
        </p>

        <h2>第2条（定義）</h2>
        <p>
          本ポリシーにおいて、以下の各号の用語は、以下のとおりの意味を有するものとします。
        </p>

        <p>
          ① 「個人情報」
          <br />
          &nbsp;&nbsp;個人情報の保護に関する法律に定める個人情報をいいます。
          <br />
          ② 「個人情報等」
          <br />
          &nbsp;&nbsp;個人情報及び、お客様等の識別に係る情報、通信サービス上の行動履歴、その他お客様の使用する端末に関して生成又は蓄積される情報であって、当社が本ポリシーに基づき収集する情報をいいます。具体的には、氏名、性別、生年月日、職業、住所、連絡先（メールアドレス、電話番号等）、決済に関する情報（クレジットカード・銀行口座に関する情報、信用情報等）、リファラ、IPアドレス、サーバーへのアクセス情報、Cookieその他の識別子、位置情報等が含まれ得ますが、これらに限られません。
          <br />
          ③ 「関係会社」
          <br />
          &nbsp;&nbsp;財務諸表等の用語、様式及び作成方法に関する規則第8条に定める関係会社をいいます。
          <br />
        </p>

        <h2>第3条（法令等の遵守）</h2>
        <p>
          当社は、個人情報等を取り扱うに際して、準拠法の法令及びガイドライン等を遵守いたします。
        </p>

        <h2>第4条（個人情報等の利用目的）</h2>
        <p>
          当社におけるお客様等の個人情報等の利用目的は、以下のとおりとします。
        </p>

        <p>
          ①
          当社及び関係会社（以下「当社等」という。）とお客様等との契約等に基づき、当社等の製品及びサービスを提供するため。
          <br />
          ②
          当社等の製品及びサービスについて、アフターサービス、メンテナンス等の対応をするため。
          <br />
          ③
          当社等の製品及びサービスに関する情報をお届けし、又はご提案させていただくため（電子メールを送信する方法によるものを含む。）。
          <br />
          ④
          当社等のウェブサイト及びその他のウェブサイトにおけるお客様等の閲覧履歴をCookieその他の識別子を用いて取得の上、お客様等の趣味嗜好を分析し、当該分析結果に基づき最適化された広告をお客様等に配信するため。
          <br />
          ⑤
          当社等、又は当社等の製品及びサービスに関して寄せられたお客様等からのご意見、ご要望にお応えするため。
          <br />
          ⑥
          当社等の製品及びサービスについて、利用状況や満足度の調査を行い、又は当社等の製品及びサービスを改善するため。
          <br />
          ⑦
          当社等の役員、従業員としての採用や、雇用関係、取引関係の適切な管理のため。
          <br />
        </p>

        <h2>第5条（個人情報等の取得及び管理）</h2>
        <p>
          1.
          当社は、法令に従い、利用目的の達成に必要な範囲内で、個人情報等を適法かつ公正に取得するものとします。
        </p>

        <p>
          2.
          当社は、個人情報等の漏洩、滅失又は毀損等を防止するとともに、個人情報を適切に管理するため、当社が適切と判断する内容で、組織的、人的、物理的、技術的な安全管理措置を講ずるものとします。当社の講じる安全管理措置に関するお問い合わせについては、第9条の連絡先までご連絡ください。
        </p>

        <h2>第6条（個人情報等の第三者に対する提供）</h2>
        <p>
          当社は、次条に定める共同利用に該当する場合、利用目的を達成するために個人情報等の取扱いを委託する場合（日本国外の者の協力を必要とする場合において、当該日本国外の者への委託を含む。）、その他法令により認められている場合を除き、本人の同意を得ることなくお客様等の個人情報等を第三者に対して提供しないものとします。
        </p>

        <h2>第7条（個人情報等の共同利用）</h2>
        <p>
          当社は、以下のとおり、個人情報等を共同利用することができるものとします。
        </p>

        <p>
          共同して利用される個人情報等の項目：第2条（定義）第2号に記載する個人情報等共同して利用する者の範囲：当社の関係会社（別途定める会社に限る。当該関係会社中、株式会社AlgoNautとあるのは、株式会社PKSHA
          Technologyと読み替えるものとする。以下同じ。）
          <br />
          共同して利用する者の利用目的：第4条（個人情報等の利用目的）に同じ。
          <br />
          共同利用に係る個人情報等の管理責任事業者：株式会社AlgoNaut（東京都文京区本郷
          二丁目35番10号 本郷瀬川ビル 代表取締役　佐野　長紀）
        </p>

        <h2>第8条（個人情報等の開示、訂正、利用停止等）</h2>
        <p>
          1.
          お客様は、次条に定める連絡先宛てに請求することによって、当社に対して、当社が保有する個人情報等の開示（第三者提供記録の開示を含む。）、訂正、追加、削除、利用の停止又は消去（以下「開示等」という。）を求めることができます。ただし、法令によって当社が開示等を拒むことが認められている場合は、この限りではないものとします。
        </p>

        <p>
          2.
          お客様等は、開示等にあたり、当社が別途定める手続に従うものとし、また、当社が別途定める費用を支払うものとします。
        </p>

        <h2>第9条（連絡先）</h2>
        <p>当社の個人情報等に関する連絡先は、以下のとおりとします。</p>

        <p>
          東京都文京区本郷二丁目35番10号　本郷瀬川ビル4F
          <br />
          株式会社AlgoNaut　個人情報等受付相談窓口
          <br />
          個人情報保護管理者
          <br />
          電話番号：03-6801-6718
          <br />
          メールアドレス：contact@pkshatech.com
        </p>

        <h2>第10条（本ポリシーの変更）</h2>
        <p>
          当社は、当社が適当と判断する方法で告知することにより、本ポリシーを変更できるものとします。当該告知は事前に行うこととしますが、緊急又はやむを得ない場合はこの限りではありません。
        </p>

        <div style={{ textAlign: 'right' }}>
          <p>以上</p>
        </div>

        <p className="bottom">
          2020年11月9日 制定
          <br />
          2022年4月1日改訂
          <br />
          <br />
          <a
            href="https://www.pkshatech.com/related_company/"
            target="_blank"
            rel="noopener noreferrer"
          >
            個人情報等を共同利用する関係会社一覧
          </a>
        </p>
      </div>
      {/* /.inner */}
    </Layout>
  )
}

export default PrivacyPolicy
