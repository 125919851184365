import React from 'react'
// import { Helmet } from 'react-helmet'
import Header from './Header'
import Footer from './Footer'
import '../styles/index.scss'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  return (
    <div>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
