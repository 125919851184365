import { Link } from 'gatsby'
import React from 'react'

class Footer extends React.Component {
  render() {
    return (
      <footer className="clear">
        <Link to={'/'} className="f-logo">
          <img src="/assets/images/logo-gray.png" alt="" />
        </Link>

        <Link to={'/policy'} className="policy">
          情報セキュリティ方針
        </Link>

        <Link to={'/privacy-policy'} className="privacy-policy">
          プライバシーポリシー
        </Link>
        <p className="copyright">COPYRIGHT(C) AlgoNaut. ALL RIGHTS RESERVED.</p>
      </footer>
    )
  }
}

export default Footer
