import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const getScrollValueFromTop = () => {
  return Math.max(
    window.pageYOffset,
    document.documentElement.scrollTop,
    document.body.scrollTop
  )
}

const Header = () => {
  const [hideHeader, setHideHeader] = useState(false)
  const [fixHeader, setFixHeader] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const onScroll = () => {
    const position = getScrollValueFromTop()
    if (position > 60) {
      setHideHeader(true)

      if (position > 600) {
        setFixHeader(true)
      } else {
        setFixHeader(false)
      }
    } else {
      setHideHeader(false)
    }
  }
  useEffect(() => {
    document.addEventListener('scroll', onScroll)
    return () => document.removeEventListener('scroll', onScroll)
  })

  return (
    <>
      <header
        className={`${hideHeader ? 'hide' : ''} ${fixHeader ? 'fixed' : ''}`}
      >
        <Link to={'/'} className="logo">
          <img src="/assets/images/logo-rgb.png" alt="" />
        </Link>

        <div className="wrapper clearfix">
          <ul className="glb-nav">
            <li>
              <AnchorLink to="/#about">About</AnchorLink>
            </li>
            <li>
              {/* <Link to={'/#news'}>News</Link> */}
              <AnchorLink to="/#news">News</AnchorLink>
            </li>
            <li>
              {/* <Link to={'/#company'}>Company</Link> */}
              <AnchorLink to="/#company">Company</AnchorLink>
            </li>
          </ul>
          <div className="contact-btn">
            <Link to={'/contact'}>
              <span className="btn-text">Contact</span>
            </Link>
          </div>
          {/* /.contact */}

          <icon
            id="sp-menu"
            onClick={(e) => {
              setIsDrawerOpen(!isDrawerOpen)
            }}
          >
            <img
              className="menu-icon"
              src="/assets/images/icon-menu.png"
              alt=""
            />
          </icon>
          {/* /#sp-menu */}
        </div>
        {/* /.wrapper */}
      </header>
      <div id="gnav" className={isDrawerOpen ? 'show' : ''}>
        <Link to={'/'} className="logo">
          <img src="/assets/images/logo-rgb.png" alt="" />
        </Link>
        <icon
          id="close-gnav"
          onClick={(e) => {
            setIsDrawerOpen(!isDrawerOpen)
          }}
        >
          <img
            className="menu-icon"
            src="/assets/images/icon-close.png"
            alt=""
          />
        </icon>
        <div className="inner">
          <ul>
            <li>
              <Link
                to={'/'}
                onClick={(e) => {
                  setIsDrawerOpen(!isDrawerOpen)
                }}
              >
                Toppage
              </Link>
            </li>
            <li>
              <Link
                to={'/#about'}
                onClick={(e) => {
                  setIsDrawerOpen(!isDrawerOpen)
                }}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to={'/#news'}
                onClick={(e) => {
                  setIsDrawerOpen(!isDrawerOpen)
                }}
              >
                News
              </Link>
            </li>
            <li>
              <Link
                to={'/#company'}
                onClick={(e) => {
                  setIsDrawerOpen(!isDrawerOpen)
                }}
              >
                Company
              </Link>
            </li>
          </ul>
          <div className="contact-btn">
            <Link to={'/contact'}>Contact</Link>
          </div>
          {/* /.contact-btn */}
          <Link to={'/policy'} className="policy">
            情報セキュリティ方針
          </Link>
        </div>
        {/* /.inner */}
      </div>
      {/* /#gnav */}
    </>
  )
}

export default Header
